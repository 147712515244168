import { Revenue } from './definitions';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { PSUserInfo } from '../types/user';
import {
  MEMBERSHIP_MONTHLY,
  MEMBERSHIP_NEW,
  MEMBERSHIP_SUSPEND,
} from './constants';
import {
  ptCrosIndiMaskRSI,
  ptCrosIndiMaskMACD,
  ptCrosIndiMaskVolume,
} from './definitions';

export const formatCurrency = (amount: number) => {
  return (amount / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export function compareISO8601TimeStrWithDate(isoStr: string, date: Date) {
  const isoDate = new Date(isoStr);
  if (isoDate > date) {
    return 1;
  } else if (isoDate < date) {
    return -1;
  } else {
    return 0;
  }
}

export function isBilledUser(userInfo: {
  membershipExpire?: string;
  role: number;
}) {
  // 当前认为，给了钱的用户一定会有订阅过期时间
  if (!userInfo.membershipExpire || userInfo.membershipExpire.length < 2) {
    return false;
  }
  if (
    userInfo.role === MEMBERSHIP_MONTHLY ||
    (userInfo.role === MEMBERSHIP_SUSPEND &&
      compareISO8601TimeStrWithDate(userInfo.membershipExpire, new Date()) ===
        1)
  ) {
    return true;
  }
  return false;
}

export const formatDateToLocal = (
  dateStr: string,
  locale: string = 'en-US',
) => {
  const date = new Date(dateStr);
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  const formatter = new Intl.DateTimeFormat(locale, options);
  return formatter.format(date);
};

export const generateYAxis = (revenue: Revenue[]) => {
  // Calculate what labels we need to display on the y-axis
  // based on highest record and in 1000s
  const yAxisLabels = [];
  const highestRecord = Math.max(...revenue.map((month) => month.revenue));
  const topLabel = Math.ceil(highestRecord / 1000) * 1000;

  for (let i = topLabel; i >= 0; i -= 1000) {
    yAxisLabels.push(`$${i / 1000}K`);
  }

  return { yAxisLabels, topLabel };
};

export const generatePagination = (currentPage: number, totalPages: number) => {
  // If the total number of pages is 7 or less,
  // display all pages without any ellipsis.
  if (totalPages <= 7) {
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  // If the current page is among the first 3 pages,
  // show the first 3, an ellipsis, and the last 2 pages.
  if (currentPage <= 3) {
    return [1, 2, 3, '...', totalPages - 1, totalPages];
  }

  // If the current page is among the last 3 pages,
  // show the first 2, an ellipsis, and the last 3 pages.
  if (currentPage >= totalPages - 2) {
    return [1, 2, '...', totalPages - 2, totalPages - 1, totalPages];
  }

  // If the current page is somewhere in the middle,
  // show the first page, an ellipsis, the current page and its neighbors,
  // another ellipsis, and the last page.
  return [
    1,
    '...',
    currentPage - 1,
    currentPage,
    currentPage + 1,
    '...',
    totalPages,
  ];
};

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Paddle

async function hashSignature(
  ts: string,
  requestBody: string,
  h1: string,
  secretKey: string,
): Promise<boolean> {
  const encoder = new TextEncoder();
  const payload = ts + ':' + requestBody;
  const key = await crypto.subtle.importKey(
    'raw',
    encoder.encode(secretKey),
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['sign'],
  );
  const signature = await crypto.subtle.sign(
    'HMAC',
    key,
    encoder.encode(payload),
  );
  const signatureHex = Array.from(new Uint8Array(signature))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return signatureHex === h1;
}

function extractValues(input: string): { ts: string; h1: string } {
  const matchTs = input.match(/ts=(\d+)/);
  const matchH1 = input.match(/h1=([a-f0-9]+)/);
  return {
    ts: matchTs ? matchTs[1] : '',
    h1: matchH1 ? matchH1[1] : '',
  };
}

export async function validateSignature(
  signature: string,
  body: string,
  secret: string,
) {
  const signatureComponents = extractValues(signature);
  return await hashSignature(
    signatureComponents.ts,
    body,
    signatureComponents.h1,
    secret,
  );
}

export interface crosIndiShowOption {
  showMACD: boolean;
  showRSI: boolean;
  showVolume: boolean;
}

export function ptCrosIndiShowOptionsBySubType(
  sub_type: number,
): crosIndiShowOption {
  let showOptions = { showMACD: false, showRSI: false, showVolume: false };
  if (sub_type & ptCrosIndiMaskMACD) {
    showOptions.showMACD = true;
  }
  if (sub_type & ptCrosIndiMaskRSI) {
    showOptions.showRSI = true;
  }
  if (sub_type & ptCrosIndiMaskVolume) {
    showOptions.showVolume = true;
  }
  return showOptions;
}
